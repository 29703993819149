import React, { useEffect } from "react"
import { navigate } from "gatsby"
import Cart from "../components/Cart"
import Contact from "../components/Contact"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"

const CartPage = ({ location }) => {
  const siteTitle = `Cart`
  // useEffect(() => {
  //   if (location.action !== "PUSH") navigate("/")
  // }, [])
  useEffect(() => {
    const siteHeader = document.querySelector(".site-header")
    siteHeader.style.background = "black"

    // window.addEventListener("scroll", handleScroll)
    // return () => {
    //   const siteHeader = document.querySelector(".site-header")

    //   siteHeader.style.background = "black"
    //   window.removeEventListener("scroll", handleScroll)
    // }
  }, [])
  return (
    <Layout>
      <SEO title="Your Cart" />
      <Cart />

      <Contact />
    </Layout>
  )
}

export default CartPage
